.grid_container {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  height: 100vh;
  background-color: #fff;
}
.header {
  grid-column: 1 / -1;
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: white;
  border-bottom: 1px solid rgba(173, 173, 173, 0.306);
  padding: 20px;
}

.main {
  grid-column: 2;
  grid-row: 2;
  background-color: #fff;
  padding: 20px 40px;
  overflow-y: auto;
}

.sidebar {
  grid-row: 2 / -1;
  width: 283px;
  position: sticky;
  padding-bottom: 40px;
  height: calc(100vh - 60px);
  overflow-y: auto;
  background-color: #fff;
  border-right: 1px solid rgba(173, 173, 173, 0.306);
}
