html,
body {
  overflow-y: hidden;
  background-color: #fff;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 100dvh;
}
.truncate-bio {
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Limit to 3 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
